body {
  font-family: "Instrument Sans", sans-serif !important;
  font-style: normal;
  background: #fbfbfb;
  overflow-x: hidden;
}
.font_family {
  font-family: "Instrument Sans", sans-serif !important;
}
.primary-color {
  color: #00a76f !important;
}
.primary-color-bg {
  background: #00a76f !important;
}

/*------ landing page header start  --------*/

.nav-header-landing button {
  border: 0px;
  background: transparent;
  font-size: 1.1rem;
  font-weight: 500;
  gap: 4px !important;
}

.logo-text-header {
  font-weight: 700;
  font-size: 1.5rem;
  color: #1f1f1f;
}
.join-us-btn {
  display: none;
}
.head-text-landing {
  font-size: 2.5rem;
  font-weight: 700;
  color: #1f1f1f;
  text-align: center;
  padding-bottom: 8px !important;
}

.para-head-text-landing {
  visibility: visible;
  animation-delay: 0.2s;
  font-size: 1.3rem;
  text-align: center;
  font-weight: 600;
  margin-bottom: 3rem;
  color: #4f4f4f;
}
.get-started-btn {
  animation-delay: 1s;
  align-items: center;
  background-color: #00a76f;
  text-decoration: none !important;
  border: none;
  border-radius: 43px;
  color: #fff;
  display: flex;
  font-size: 16px;
  justify-content: center;
  letter-spacing: 1px;
  margin: 0 auto;
  padding: 0 24px;
  font-weight: 600;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  padding: 12px 20px;
}
.get-started-btn:hover {
  background-color: #00a76f;
}

.main-section-landing {
  top: 3rem;
}
.img-landing {
  width: 70% !important;
  padding-top: 20px;
}
.tabs-with-feature-container {
  margin-top: 3rem;
  color: white !important;
  padding: 5rem 0rem;
}
.tabs-with-feature-container .container-div-color {
  background: #00a76f;
  padding: 24px 16px;
  border-radius: 16px;
}
.active-btn-landing {
  border: 0;
  background: none;
  padding: 0;
  font-size: 1rem;
  font-weight: 500;
  color: #2cb98a;
}
.active-btn-landing.active {
  color: white;
}

.image-feature-landing {
  border-radius: 16px;
}
.feature-heading-landing {
  font-size: 1.5rem;
  font-weight: 600;
}
.feature-para-landing {
  font-size: 1rem;
  line-height: 22px;
}
.active-container {
  background: #10bb82;
  border-radius: 12px;
}
/* testinomial  */
.testimonial-section {
  background-color: #fff;
  color: #212529;
}

.testimonial-section h2 {
  font-size: 2rem !important;
  font-weight: 700;
  color: #1f1f1f;
  margin-bottom: 1rem;
}

.testimonial-section p {
  font-size: 1rem;
  color: #59c9a3;
}

.testimonial-item {
  background-color: #fff;
  border-radius: 16px !important;
  box-shadow: 0 2px 10px 0px rgba(0, 0, 0, 0.1) !important;
  transition: transform 0.3s ease;
}

.testimonial-item:hover {
  transform: translateY(-5px);
}

.testimonial-item p {
  text-align: start;
  line-height: 24px;
}

.testimonial-item h5 {
  text-align: start;
}

.testimonial-item .inactive {
  color: rgba(255, 193, 7, 0.4);
}

.rating svg {
  color: #ffc107;
}

.author-info img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}
.why-choose-us {
  background-color: #f9f9f9;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.section-head h4 {
  font-size: 2rem !important;
  font-weight: 700;
  color: #1f1f1f;
  margin-bottom: 1rem;
}

.section-head h4 span {
  font-weight: 700;
  color: #00a76f;
}

.section-subtitle {
  font-size: 1rem !important;
  color: darkslategray;
  line-height: 24px;
  padding-bottom: 1rem !important;
}

.feature-item {
  background-color: #fff;
  text-align: center;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.3s;
}

.feature-item:hover {
  background-color: #f3f3f3;
  color: #fff;
  transform: translateY(-5px);
}

.feature-item .icon {
  font-size: 40px;
  margin-bottom: 15px;
  color: #00a76f;
  padding: 15px;
  border-radius: 50%;
  display: inline-block;
}

.feature-item h6 {
  font-size: 1.2rem !important;
  font-weight: 600;
  margin-bottom: 10px;
  color: #00a76f;
}

.feature-item p {
  font-size: 1rem !important;
  color: #666;
  line-height: 22px;
}

@media (min-width: 992px) {
  .feature-item {
    padding: 30px;
  }
  .head-text-landing {
    font-size: 4rem;
    font-weight: 700;
    color: #1f1f1f;
  }
  .nav-header-landing button {
    border: 0px;
    background: transparent;
    font-size: 1.1rem;
    font-weight: 500;
    gap: 16px;
  }
  .para-head-text-landing {
    visibility: visible;
    animation-delay: 0.2s;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 3rem;
    color: #4f4f4f;
  }
  .join-us-btn {
    border-radius: 50px;
    font-weight: 600;
    display: block;
    font-size: 1.1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #1f1f1f;
  }
  .feature-item .icon {
    font-size: 50px;
    padding: 20px;
  }

  .section-head h4 {
    font-size: 36px;
  }

  .section-subtitle {
    font-size: 18px;
  }
}

.faq-container {
  color: #1f1f1f;
  font-family: Arial, sans-serif;
}

.faq-question {
  font-size: 16px !important;
  font-weight: 500;
}

.faq-answer {
  font-size: 16px !important;
  font-weight: normal;
}
.footer-container-div {
  background: #00a76f !important;
}

.hero-section {
  background: url("./asset/image/about_bg.svg") no-repeat center center/cover;
  color: #1f1f1f;
  padding: 100px 15px;
  text-align: center;
  position: relative;
}

.hero-title {
  font-size: 2rem;
  font-weight: bold;
  max-width: 700px;
  margin: 0 auto;
}

.hero-subtitle {
  font-size: 1.2rem;
  margin-top: 10px;
}

.ag-courses_item {
  overflow: hidden;
}
.ag-courses_item:hover .ag-courses-item_title {
  color: white;
}
.ag-courses-item_link {
  display: block;
  padding: 1.2rem;
  background-color: #ffffff;
  overflow: hidden;
  position: relative;
  border-radius: 16px;
}
.ag-courses-item_link:hover,
.ag-courses-item_link:hover .ag-courses-item_date-box {
  text-decoration: none;
  color: white;
}
.ag-courses-item_link:hover .ag-courses-item_bg {
  -webkit-transform: scale(10);
  -ms-transform: scale(10);
  transform: scale(10);
}
.ag-courses-item_title {
  margin-bottom: 0.8rem;
  overflow: hidden;
  font-weight: 600;
  font-size: 1.5rem;
  color: #1f1f1f;
  z-index: 2;
  position: relative;
}
.ag-courses-item_date-box {
  font-size: 1rem;
  color: #909090;
  z-index: 2;
  position: relative;
  line-height: 24px;
}
.ag-courses-item_date {
  font-weight: bold;
  color: #f9b234;

  -webkit-transition: color 0.5s ease;
  -o-transition: color 0.5s ease;
  transition: color 0.5s ease;
}
.ag-courses-item_bg {
  height: 128px;
  width: 128px;
  background-color: #f9b234;

  z-index: 1;
  position: absolute;
  top: -75px;
  right: -75px;

  border-radius: 50%;

  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.ag-courses_item:nth-child(2n) .ag-courses-item_bg {
  background-color: #3ecd5e;
}
.ag-courses_item:nth-child(3n) .ag-courses-item_bg {
  background-color: #e44002;
}
.ag-courses_item:nth-child(4n) .ag-courses-item_bg {
  background-color: #952aff;
}
.ag-courses_item:nth-child(5n) .ag-courses-item_bg {
  background-color: #cd3e94;
}
.ag-courses_item:nth-child(6n) .ag-courses-item_bg {
  background-color: #4c49ea;
}

@media only screen and (max-width: 979px) {
  .ag-courses_item {
    -ms-flex-preferred-size: calc(50% - 30px);
    flex-basis: calc(50% - 30px);
  }
  .ag-courses-item_title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .ag-format-container {
    width: 96%;
  }
  .img-landing {
    width: 100% !important;
  }
  .hero-section {
    background: url("./asset/image/about_bg.svg") no-repeat center center/cover;
    color: #1f1f1f;
    padding: 50px 15px;
    text-align: center;
    position: relative;
  }
}
@media only screen and (max-width: 639px) {
  .ag-courses_item {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
  .ag-courses-item_title {
    line-height: 1;
    font-size: 24px;
  }
  .ag-courses-item_link {
    /* padding: 22px px; */
  }
  .ag-courses-item_date-box {
    font-size: 16px;
  }
  .head-text-landing {
    font-size: 2rem;
  }
  .para-head-text-landing {
    font-size: 1.2rem;
  }
}
.about-us-our-team {
  figure {
    width: 500px;
    aspect-ratio: 8 / 5;
    --bg: hsl(330 80% calc(90% - (var(--hover) * 10%)));
    --accent: #dfffcd;
    transition: background 0.2s;
    background: radial-gradient(
        circle at top left,
        var(--accent),
        transparent 75%
      ),
      var(--bg);
    margin: 0;
    position: relative;
    overflow: hidden;
    border-radius: 1.5rem;
  }

  figure:after {
    content: "";
    position: absolute;
    width: 20%;
    aspect-ratio: 1;
    border-radius: 50%;
    bottom: 0%;
    left: 10%;
    background: linear-gradient(-65deg, var(--bg) 50%, var(--accent) 50%);
    filter: blur(25px);
    transform: translateX(calc(var(--hover) * 15%))
      scale(calc(1 + (var(--hover) * 0.2)));
    transition: transform 0.2s, background 0.2s;
  }

  img {
    position: absolute;
    left: 20%;
    top: 15%;
    width: 100%;
    transform: translateX(calc(var(--hover) * -15%))
      scale(calc(1 + (var(--hover) * 0.2)));
    transition: transform 0.2s;
  }

  article {
    --hover: 0;
  }

  article:hover {
    --hover: 1;
  }
  .name_about {
    font-size: 1.5rem;
    font-weight: 600 !important;
    padding-top: 8px;
  }
  .name_para {
    font-size: 1rem;
    margin-bottom: 32px !important;
  }
  .section-subtitle {
    font-size: 1rem !important;
    padding-bottom: 0rem !important;
  }
}
.faq-row .row-title-text {
  font-family: "Instrument Sans", sans-serif !important;
  font-size: 1.1rem !important;
}

.faq-row .row-content-text {
  font-family: "Instrument Sans", sans-serif !important;
}

.icon_about_us_page {
  font-size: 35px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;
  background: #00a76f;
  margin-bottom: 16px;
  border-radius: 8px;
  color: white;
}
.who-we-are-description {
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0.5px 1px 1px;
}
