html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden !important;
}
button {
  box-shadow: none !important;
  border: none !important;
}
.logo-width {
  width: 90px;
}
/* common styling  */
.heading-one {
  font-weight: 700 !important;
  font-size: 3rem !important;
  line-height: 68px;
}
.heading_three {
  font-weight: 700 !important;
  font-size: 1.8rem !important;
}
.heading_four {
  font-weight: 600 !important;
  font-size: 1.4rem !important;
}
.heading_five {
  font-weight: 600 !important;
  font-size: 1.2rem !important;
}
.para_text {
  font-weight: 400 !important;
  font-size: 1rem !important;
}
.para_text_profile {
  font-weight: 500 !important;
  font-size: 1.2rem !important;
}
.para_text_500 {
  font-weight: 500 !important;
  font-size: 1rem !important;
}
.para_text_600 {
  font-weight: 600 !important;
  font-size: 1rem !important;
}
.full-height {
  height: 97vh;
}
.pri_bg {
  background: #00a76f !important;
}
.pri_border_bottom {
  border-bottom: 2px solid #00a76f !important;
}
.pri_text {
  color: #00a76f !important;
}
.text_black {
  color: #1c252e !important;
}
.text_gray {
  color: #393939 !important;
}
.text_lightGray {
  color: #908f90 !important;
}

.text_white {
  color: white !important;
}
.pri_radious {
  border-radius: 8px;
}
.sec_radious {
  border-radius: 16px;
}
.text_normal {
  font-size: 1rem !important;
}
.label_text {
  font-size: 0.9rem;
  line-height: 24px;
  margin-bottom: 4px;
  text-align: left;
}
.label_text_profile {
  font-size: 1rem;
  line-height: 24px;
  margin-bottom: 6px;
  text-align: left;
}

.button_design_withoutWeight {
  padding: 12px 16px !important;
  font-size: 1rem !important;
  border-radius: 8px !important;
  background: none;
  border: 0px;
  color: #5f5f5f;
}
.button_design {
  padding: 6px 16px !important;
  font-size: 1.1rem !important;
  font-weight: 500 !important;
  border-radius: 8px !important;
  background: none;
  border: 0px;
  gap: 4px !important;
}
.spinner_class {
  width: 24px !important;
  height: 24px !important;
  padding: 0px !important;
  margin: 0px !important;
  font-size: 1rem !important;
}
.common_loader {
  z-index: 1000;
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon_dimention {
  width: 24px !important;
  height: 24px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
}
.input_feild {
  font-size: 1rem;
  padding: 12px 16px !important;
  line-height: 24px !important;
  height: 48px !important;
  border-radius: 8px !important;
}
.label_text_large {
  font-size: 1.2rem;
  font-weight: 600;
}
.disable_button {
  opacity: 0.5;
  cursor: context-menu !important;
}
.library-fee-notification {
  font-size: 0.9rem;
  font-weight: 400;
  color: gray;
  line-height: normal !important;
}
/* -----------login and otp start-------- */
.login-image-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: white;
  z-index: 10;
  padding: 20px;
  font-size: 2rem;
  margin-top: 23%;
  margin-left: 10%;
  font-size: 3.5rem !important;
}
.login-img {
  object-fit: cover;
  width: auto;
  height: 99vh;
  opacity: 0.5;
  overflow: hidden !important;
}
.react-tel-input .form-control {
  height: 48px !important;
  font-family: "Instrument Sans", sans-serif !important;
  font-size: 1.2rem !important;
  width: 300px !important;
  box-shadow: none !important;
  padding-left: 45px !important;
}
.sent_otp_btn {
  width: 300px;
}
.opt-container {
  width: 300px;
  gap: 6px;
}
.opt-container input {
  height: 45px !important;
  width: 45px !important;
  margin: 0px !important;
  padding: 5px !important;
  border-radius: 8px !important;
}
.phone_input {
  width: 300px !important;
}
.login_heading {
  font-size: 28px !important;
  color: #1f1f1f;
}
/* -----------login and otp end-------- */

/* -----------sidenav start-------- */
.side_nav_container {
  display: flex;
  flex-direction: row;
}
.side_nav {
  width: 15%;
  min-width: 250px;
}
.side_nav_containt {
  width: 85%;
}
.active_btn_sidebar {
  background-color: rgba(0, 167, 111, 0.08) !important;
  color: #00a76f;
  border-radius: 8px !important;
  font-weight: 600;
}
.btn_sidebar {
  width: 80%;
}
.dashboar_header_container {
  background: rgba(0, 167, 111, 0.03);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.01);
  position: sticky;
  z-index: 500;
  top: 0px;
  border-bottom: 1px solid #f2fffb;
}

.profile_detail_container {
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 1px;
}
.shift_card {
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 1px;
  background: #303030;
  border-radius: 16px;
}
.library_facilities_pri {
  border: 2px solid rgb(96, 230, 0) !important;
  color: rgb(96, 230, 0);
  border-radius: 8px;
  width: 100px !important;
  text-align: center;
}
.library_facilities_sec {
  border: 2px solid gray;
  border-radius: 8px;
  width: 100px !important;
  text-align: center;
}
.shift-row {
  border: 1px solid #dee2e6;
  border-radius: 16px;
  box-shadow: 0 1px 10px rgba(126, 126, 126, 0.05);
}

.shift-name-top {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  background: #f8f8f8;
  border-radius: 16px 16px 0px 0px;
}
.form-input-lib {
  height: 40px;
  padding: 6px;
  border-radius: 8px;
}
.shift-heading-lib {
  color: #4f4f4f !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
}
.shift-row .form-control {
  margin-right: 4px;
}
.form-shift-lib {
  padding: 15px !important;
}
.btn-sm {
  font-size: 0.8rem;
}

.btn-primary {
  margin-top: 10px;
}
/* ----------- Sidebar Nav and Profile End----------- */

/* ----------- Dashboard start ----------- */
.text_card_container_dash {
  box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  background: white;
  padding: 24px;
  border-radius: 15px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
.text_card_container_dash_small {
  box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  background: white;
  padding: 16px;
  border-radius: 15px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
  min-width: 250px;
}
.card_small_width {
  min-width: 260px;
}
.card_para_text_dash {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.57143;
  color: #1c252e;
  margin-bottom: 0px;
}
.card_heading_text_dash {
  font-size: 1.875rem;
  margin-top: 8px;
  margin-bottom: 0px;
  font-weight: 700;
  color: #1c252e;
}
.icon_card_dash {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  color: #ffffff;
}
/* pie chart styles  */
.pie_char_div_con {
  position: relative !important;
  height: 70px !important;
  width: auto !important;
}

/* subscription table  */
.cart_harding_text {
  line-height: 1.55556 !important;
  font-weight: 600 !important;
  font-size: 1.125rem;
  color: #1c252e !important;
}
.background_ul {
  background: rgb(244, 246, 248);
  border-radius: 8px;
}
.table td,
.table th {
  border-top: 0px !important;
}
.custom-table-dashboard {
  margin-bottom: 0px !important;
}
/* pagination  */
.pagination li button {
  width: 24px;
  height: 24px;
  background: transparent !important;
  font-size: 1rem;
}
.pagination-info i {
  font-size: 0.8rem;
}
.on_hover_visible {
  display: none;
}
.header-cell {
  cursor: pointer;
  gap: 4px;
  font-size: 0.9rem;
  font-weight: 700 !important;
  color: rgb(99, 115, 129) !important;
}
.table_row_cell {
  color: rgb(28, 37, 46) !important;
  border-bottom-color: rgba(145, 158, 171, 0.2);
  border-bottom-style: dashed;
  border-bottom-width: 1px;
  font-size: 0.9rem !important;
}
.table_row_cell button span {
  font-size: 1.1rem !important;
  color: rgb(28, 37, 46);
}
.green_color_check {
  color: #00a76f !important;
}
.view_all_btn {
  color: #00a76f;
  font-size: 0.9rem !important;
}
.search_input {
  font-size: 0.9rem;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid gray;
  outline: none;
  color: rgba(75, 75, 75, 0.8);
}
.search_input {
  font-size: 0.9rem;
  padding: 6px 8px;
  border-radius: 8px;
  border: 1px solid gray;
  outline: none;
  color: rgba(75, 75, 75, 0.8);
}
.pie_char_div_con_male_female {
  position: relative;
  width: auto;
}
.chart-container {
  position: relative;
  width: 100%;
  height: 300px;
}
/* .chart-container {
  #chartjs-tooltip {
    opacity: 0;
    transition: opacity 0.2s ease;
    background: rgba(255, 255, 255, 0.8);
    padding: 4px 8px;
    border-radius: 8px;
    backdrop-filter: blur(8px);
    z-index: 10;
    pointer-events: none;
  }

  .tooltip-content {
    display: flex;
    align-items: center;
  }

  .tooltip-circle {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 6px;
  }

  .tooltip-label {
    font-size: 0.9rem;
    color: #333;
  }

  .tooltip-value {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
  }
} */
/* ----------- Dashboard end ----------- */

/* ---------- Add student and renew subscription form start ---------- */
.react-datepicker-wrapper {
  width: 100% !important;
}
.select_duration_btn {
  font-size: 0.8rem !important;
  padding: 4px 8px !important;
  box-shadow: none !important;
  border: none !important;
}
.qr-code-container {
  background-color: #ffffff;
  padding: 12px;
  border-radius: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.qr-code-header {
  margin-bottom: 15px;
}

.qr-code-logo {
  width: 80px;
  margin-bottom: 10px;
}

.qr-company-name {
  font-size: 24px;
  font-weight: 700;
  color: #0070f3;
}

.qr-library-name {
  font-size: 20px;
  font-weight: 700;
  color: rgb(0, 167, 111);
  margin-bottom: 8px;
}

.qr-code-content {
  margin-bottom: 15px;
}

.qr-code-footer {
  font-size: 16px;
  color: #888;
}

/* ---------- Add student form end---------- */

.sort-icon {
  font-size: 16px;
  margin-left: 8px;
  vertical-align: middle;
  cursor: pointer;
}

.header-cell:hover .sort-icon {
  color: #00a76f;
}
.pagination {
  display: flex;
  align-items: center;
  margin-top: 12px;
  justify-content: end;
}

.page-item {
  margin: 0 5px;
}

.page-item .page-link {
  border: none;
  background: transparent;
  width: 24px;
  height: 24px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1f1f1f;
}

.page-item .page-link:focus {
  box-shadow: none;
}

.page-item.active .page-link {
  background-color: #00a76f !important;
  color: white;
  width: 24px !important;
  height: 24px !important;
}

.page-item.disabled .page-link {
  color: #6c757d;
  cursor: not-allowed;
}

.material-icons {
  font-size: 24px;
}

.page-link span {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}
.checkbox_design {
  width: 16px !important;
  height: 16px !important;
  padding: 4px !important;
  background: #00a76f !important;
}
.display_none {
  display: none !important;
}
.dashboard_all_container {
  padding-right: 36px;
}
.table_dashboard {
  height: 404px;
}
.piechart_dashboard {
  height: 420px;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.login-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.login-content-section h2 {
  margin: 0;
  font-size: 24px;
}

.login-container {
  display: flex;
  height: 100vh;
  flex-direction: column;
}

.login-image-section {
  position: relative;
  width: 100%;
  height: 70%;
  background: black;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-image: url("../src/asset/image/mob_login_bg.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.login-text-overlay {
  position: absolute;
  top: 40%;
  left: 7%;
  transform: translateY(-50%);
  color: #008e60;
}

.login-text-overlay h1 {
  margin: 0;
  font-size: 60px !important;
  opacity: 0.3;
  line-height: 125%;
}

.login-content-section {
  background-color: white;
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-content-section h2 {
  margin: 0;
  font-size: 24px;
}

.table-responsive {
  max-height: 300px;
}
/*   scroll  */
.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-x-scroll::-webkit-scrollbar {
  background-color: transparent;
  height: 5px;
}

.overflow-y-scroll:hover::-webkit-scrollbar-thumb {
  background-color: rgba(96, 93, 93, 0.2);
  border-radius: 20px;
  cursor: pointer;
}
.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-y-scroll::-webkit-scrollbar {
  background-color: transparent;
  width: 5px;
}

.overflow-y-scroll:hover::-webkit-scrollbar-thumb {
  background-color: rgba(96, 93, 93, 0.2);
  border-radius: 20px;
  cursor: pointer;
}
.dynamic-library {
  background: linear-gradient(90deg, #ff6f61, #6a5acd, #ffb347, #48c9b0);
  background-size: 300% 300%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientShift 6s ease infinite;
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
/*-----Seat Management Start--------*/
.seat-mapping-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 28px;
  /* justify-content: space-between; */
}
.seat_container {
  height: 50px;
  width: 60px;
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.05);
  font-size: 14px;
  font-weight: 500;
  color: #4f4f4f;
}

.seat_container_design {
  border: 1px solid #eef5f3 !important;
  background: #ffffff;
}
.seat_container_design_available {
  border: 1px solid #adecd7 !important;
  background: #f4fffb;
}
.seat_container_design_only_morning_booked {
  border: 1px solid #ffef94 !important;
  background: #fffdf1;
}
.seat_container_design_only_evening_booked {
  border: 1px solid #81e9fd !important;
  background: #dcf9ff;
}
.seat_container_design_only_night_booked {
  border: 1px solid #adadad !important;
  background: #f3f3f3;
}
.seat_container_design_booked {
  border: 1px solid #00a76f !important;
  background: #00a76f !important;
  color: white;
}
.buffer-seat-design {
  /* border: 2px dotted red !important; */
  /* color: rgb(255, 133, 133) !important; */
}

.notification-buffer-days{
  position: absolute;
  height:20px;
  width: 20px;
  background: #fff;
  font-size: 12px !important;
  border-radius: 50%;
  border: 1px solid rgb(129, 129, 129);
  display: flex;
  align-items: center;
  justify-content: center;
  right: -4px;
  top: -4px;
}
.seat-detail-container {
  min-width: 320px !important;
  max-width: 320px !important;
  border: 1px solid #dee2e6 !important;
  border-radius: 16px !important;
}
.small-box-seat-color {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid #dee2e6;
}

.small-box-label-text {
  font-size: 14px;
  color: #505050;
}
.seat-detail-header {
  background: #f8f8f8 !important;
  border-bottom: 1px solid #dee2e6;
  padding: 12px 20px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.seat-detail-seatno {
  color: #4f4f4f;
  font-size: 16px;
  font-weight: 600;
}
.seat-detail-floorname {
  color: #4f4f4f;
  font-size: 14px;
}
.seat-detail-body {
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.shift-detail-shift-name {
  font-size: 14px;
  font-weight: 500;
  color: #505356;
}
.shift-detail-shift-status-booked {
  color: #d2b702;
  background: #faf4d0;
  cursor: pointer;
}
.shift-detail-shift-status-available {
  color: #00a76f;
  background: #f8f8f8;
}
.shift-detail-shift-status {
  color: #505356;
  background: #f8f8f8;
}
.shift-detail-shift-status-common {
  font-size: 12px;
  font-weight: 500;
  padding: 2px 8px;
  border-radius: 8px;
}
.shift-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.padding-bottom-12 {
  padding-bottom: 12px;
  border-bottom: 1px dashed #dddddd;
}
.shift-detail-user-name {
  font-size: 12px;
  color: #505356;
}
.shift-detail-start-end-date {
  font-size: 12px;
  color: #505356;
}
/*-----Seat Management end--------*/

/* check seat avalibality start  */
.date-time-check-ava {
  background: #eefff9;
  padding: 4px 8px;
  border-left: 2px solid #008e60;
  border-right: 2px solid #008e60;
  font-size: 12px;
  justify-content: space-between;
}
.floor-text-check-seatAvai {
  font-size: 12px;
  border-radius: 8px;
  background: #efefef;
  color: #909090;
  padding: 4px 6px;
}
.seat-text-check-seatAvai {
  font-size: 14px;
}

/* check seat avalibality end  */

.add-student-btn-header {
  padding: 2px 8px 2px 2px !important;
  border-radius: 8px;
  border: none !important;
  outline: none !important;
}
.spinner-border-btn {
  width: 20px !important;
  height: 20px !important;
}
.floor-wise-container {
  border-radius: 16px;
  padding: 8px 16px;
  background: #f8f8f8;
}
.horizontal-line-parallel {
  width: 100%;
  height: 1px;
  border-top: 1px solid #00a76f;
}
.floor-container {
  min-width: 300px !important;
}

@media (min-width: 900px) {
  .login-container {
    flex-direction: row;
  }

  .login-image-section {
    width: 75%;
    height: 100%;
  }

  .login-content-section {
    width: 25%;
    height: 100%;
  }
  .login-content-section {
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .login-image-section {
    background-image: url("../src/asset/image/bg_login.jpg");
  }
  .login-text-overlay {
    position: absolute;
    top: 30%;
    left: 15%;
    transform: translateY(-50%);
    color: #008e60;
  }

  .login-text-overlay h1 {
    margin: 0;
    font-size: 60px !important;
    opacity: 1;
    line-height: 125%;
  }
}
@media screen and (max-width: 900px) {
  .side_nav_container {
    display: flex;
    flex-direction: column;
  }
  .side_nav {
    width: 100%;
  }
  .side_nav_containt {
    width: 100%;
    margin-left: 0px !important;
  }
  .display_none_900 {
    display: none !important;
  }
  .display_block_900 {
    display: block !important;
  }
  .button_menu_mob {
    position: absolute;
    top: 25px;
    left: 95vw;
    padding: 0px !important;
    z-index: 1000;
  }
  .space_menu_900 {
    margin-right: 20px !important;
  }
  .dashboard_all_container {
    padding-right: 0px;
  }
}
@media screen and (max-width: 700px) {
  .display_none_700 {
    display: none !important;
  }
  .display_block_700 {
    display: block;
  }
  .button_design_withoutWeight {
    padding: 6px 12px !important;
    font-size: 0.9rem !important;
    border-radius: 8px !important;
    background: none;
    border: 0px;
    color: #5f5f5f;
  }
  .button_design {
    padding: 10px 12px !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
    border-radius: 8px !important;
    background: none;
    gap: 4px !important;
  }
  .space_menu_900 {
    margin-right: 40px !important;
  }
  .button_menu_mob {
    position: absolute;
    top: 26px;
    left: 91vw;
    padding: 0px !important;
  }
  .heading_three {
    font-weight: 700 !important;
    font-size: 1.4rem !important;
  }
  .table_dashboard {
    height: auto;
  }
} /* MessageNotificationScreen.css */

/* Container Styles */
.message-container {
  position: relative;
  height: 88vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
  padding: 20px 20px 80px 20px;
}

/* Coming Soon Section Styles */
.coming-soon-section {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.coming-soon-content {
  text-align: center;
  max-width: 600px;
  background: white;
  border-radius: 20px;
}

.coming-soon-title {
  font-size: 2.8rem;
  font-weight: bold;
  background: linear-gradient(135deg, #00a76f 0%, #2196f3 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
}

.coming-soon-text {
  font-size: 1.2rem;
  color: #2c3e50;
  margin-bottom: 16px;
  line-height: 1.6;
  font-weight: 500;
}

.coming-soon-subtext {
  font-size: 1rem;
  color: #666;
  line-height: 1.6;
}

/* Message List Styles */
.message-list {
  flex: 1;
  overflow-y: auto;
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
}

/* Scrollbar Styles */
.message-list::-webkit-scrollbar {
  width: 6px;
  display: none;
}

/* Message Styles */
.message {
  margin-bottom: 40px;
  display: flex;
}

.message.user {
  justify-content: flex-end;
}

.message.system {
  justify-content: flex-start;
}

.message-content {
  max-width: 70%;
  padding: 16px 20px;
  border-radius: 20px;
  word-wrap: break-word;
  position: relative;
}

.message.user .message-content {
  background-color: rgba(0, 167, 111, 0.08);
  color: #333;
  border-bottom-right-radius: 5px;
}

.message.system .message-content {
  background: white;
  border: 1px solid rgba(0, 167, 111, 0.1);
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.04);
  border-bottom-left-radius: 5px;
}

.message-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #00a76f;
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(0, 167, 111, 0.1);
}

.message-text {
  font-size: 0.95rem;
  line-height: 1.5;
  color: #444;
}

.message-time {
  position: absolute;
  bottom: -20px;
  right: 10px;
  font-size: 0.75rem;
  color: #999;
}

/* Input Area Styles */
.message-input-area-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  border-top: 1px solid rgba(0, 167, 111, 0.1);
}

.message-input-area {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  position: relative;
  z-index: 1000;
}

.message-input {
  flex: 1;
  padding: 12px 18px;
  border: 1px solid #ddd;
  border-radius: 25px;
  outline: none;
  font-size: 0.95rem;
  transition: all 0.3s ease;
}

.message-input:focus {
  border-color: #00a76f;
  background-color: white;
}

.send-button {
  background-color: #00a76f;
  border: none;
  color: white;
  padding: 12px;
  text-align: center;
  text-decoration: none;
  font-size: 1rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-left: 10px;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px rgba(0, 167, 111, 0.2);
}

.send-button:hover {
  background-color: #008f5d;
  box-shadow: 0 4px 12px rgba(0, 167, 111, 0.3);
  transform: translateY(-1px);
}

.send-button:disabled {
  background-color: #e0e0e0;
  color: #999;
  cursor: not-allowed;
  box-shadow: none;
  transform: none;
}

.material-symbols-outlined {
  font-size: 20px;
}
.view-adhar-btn {
  color: #00a76f !important;
  text-decoration: none !important;
}
.view-adhar-btn span {
  font-size: 0.9rem !important;
}
.profile-image-view {
  max-width: 150px !important;
}
.profile-aadhar-view {
  max-width: 150px !important;
}

.library-facilities-card {
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
}

.library-facilities-title {
  text-align: center;
  font-size: 1.5rem;
  color: #343a40;
  margin-bottom: 20px;
}

.library-facilities-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.facility-item {
  padding: 6px 12px;
  border: 1px solid #ced4da;
  border-radius: 20px;
  background: #f1f3f5;
  color: #495057;
  cursor: pointer;
  transition: all 0.3s ease;
}

.facility-item:hover {
  background: #e9ecef;
}

.facility-item.selected {
  background: #00a76f;
  color: white;
  border-color: #00a76f;
}

.facility-item.add-other {
  border: 1px dashed #ced4da;
  color: #6c757d;
}

.custom-input {
  border: none;
  outline: none;
  background: transparent;
  color: #495057;
  padding: 5px;
  width: 100%;
  max-width: 200px;
}

/* ------switch profile css start-------- */
.profile-menu-container {
  position: relative;
}

.profile-btn {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.profile-btn:focus {
  outline: none;
}

.profile-btn:hover {
  transform: scale(1.1);
}

.profile-avatar {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgb(0, 167, 111);
  color: white;
  font-weight: 600;
  font-size: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.popover-menu {
  position: absolute;
  top: 50px;
  right: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  animation: fadeIn 0.2s ease-out;
}

.popover-arrow {
  position: absolute;
  top: -6px;
  right: 20px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
}

.popover-content {
  padding: 10px 0;
}

.menu-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px 16px !important;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease;
  gap: 12px !important;
}

.menu-item span {
  font-size: 14px !important;
  text-wrap: nowrap;
}

.menu-item:hover {
  background-color: #f8f9fa;
}

.menu-item.logout:hover {
  background-color: #fef2f2;
}

.menu-divider {
  margin: 8px 16px;
  border-bottom: 1px solid #e5e7eb;
}

.menu-icon {
  margin-right: 8px;
  color: #6b7280;
}

.logout .menu-icon {
  color: #dc3545;
}
#switchAccountModalLabel {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #1f1f1f;
}
.switchAccountModalLabel-header {
  margin: 12px 16px !important;
  margin-bottom: 4px !important;
  padding: 0px !important;
  padding-bottom: 12px !important;
}
.list-group-item-switch {
  font-size: 16px !important;
  color: #494949 !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* ------switch profile css end-------- */

/* ------- terms and condition start ------- */
.editor-container {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
}

.toolbar {
  background: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
  padding: 8px;
}

.toolbar-buttons {
  display: flex;
  align-items: center;
  gap: 4px;
}

.format-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border: none;
  background: transparent;
  border-radius: 4px;
  color: #495057;
  transition: all 0.2s ease;
}

.format-btn:hover {
  background: #e9ecef;
  color: #212529;
}

.format-btn.active {
  background: #e9ecef;
  color: #0d6efd;
}

.divider {
  width: 1px;
  height: 24px;
  background: #dee2e6;
  margin: 0 4px;
}

.editor-content {
  padding: 16px;
  font-size: 15px;
  line-height: 1.6;
  color: #212529;
}

.editor-content:empty:before {
  content: "Start typing your content...";
  color: #adb5bd;
  pointer-events: none;
}

.editor-content:focus {
  outline: none;
}

.editor-content ul,
.editor-content ol {
  padding-left: 1.5rem;
  margin: 0.5rem 0;
}

.editor-content li {
  margin: 0.25rem 0;
}

.material-symbols-outlined {
  font-size: 20px;
}
.terms-content ul li {
  margin-bottom: 10px;
  line-height: 1.5;
}
.checkbox-container {
  display: flex;
  align-items: start !important;
}
.checkbox-container input {
  margin-top: 6px;
}
.terms-condition-container-internal {
  padding-left: 20px;
}
@media (hover: hover) {
  .format-btn:hover {
    transform: translateY(-1px);
  }
}

/* ------- terms and condition end ------- */

/* ---------- help and support section start --------- */
.help-support-container {
  margin: 0 auto;
}

.help-header {
  text-align: center;
  margin-bottom: 3rem;
  animation: fadeIn 0.6s ease-in;
}

.help-header h1 {
  font-size: 2.5rem;
  color: #1e293b;
  margin-bottom: 1rem;
}

.help-header p {
  font-size: 1.1rem;
  color: #64748b;
}

.contact-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 4rem;
}

.contact-card {
  background: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  animation: slideUp 0.4s ease-out;
}

.contact-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.contact-icon {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  display: block;
}

.contact-card h3 {
  font-size: 1.2rem;
  color: #1e293b;
  margin-bottom: 0.5rem;
}

.contact-card p {
  color: #64748b;
  margin-bottom: 1rem;
}

.contact-card a {
  color: #2563eb;
  text-decoration: none;
  font-weight: 500;
}

.faq-section {
  animation: fadeIn 0.8s ease-in;
}

.faq-section h2 {
  font-size: 2rem;
  color: #1e293b;
  margin-bottom: 2rem;
  text-align: center;
}

.faq-item {
  background: white;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-question h3 {
  font-size: 1.1rem;
  color: #1e293b;
  margin: 0;
}

.faq-icon {
  font-size: 1.5rem;
  color: #64748b;
  transition: transform 0.3s ease;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.faq-item.expanded .faq-answer {
  max-height: 200px;
  margin-top: 1rem;
}

.faq-answer p {
  color: #64748b;
  line-height: 1.6;
}
.student-count-table {
  font-size: 14px;
  font-weight: 500;
  color: #495057;
  padding: 8px 12px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .help-support-container {
    padding: 1rem;
  }

  .help-header h1 {
    font-size: 2rem;
  }

  .contact-grid {
    grid-template-columns: 1fr;
  }

  .faq-section h2 {
    font-size: 1.5rem;
  }

  .faq-question h3 {
    font-size: 1rem;
  }
}

/* -------- help and support end -------- */

/* ---------- scroller css start-------- */

/* width */
.table-scroll::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.table-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
.table-scroll::-webkit-scrollbar-thumb {
  background: rgb(233, 233, 233);
  border-radius: 10px;
}

/* Handle on hover */
.table-scroll::-webkit-scrollbar-thumb:hover {
  background: #b4b4b4;
}
/* ---------- scroller css End-------- */

/* --------- Fee reciept start ---------- */
.custom-receipt-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 34px;
}

.custom-receipt {
  width: 420px !important;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.custom-receipt-header {
  background-color: #00a76f;
  color: white;
  text-align: center;
  padding: 12px 0;
  border-bottom: 3px solid #00a76f;
}

.custom-receipt-header h1 {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
}

.custom-receipt-content {
  padding: 20px;
}

.custom-receipt-section {
  margin-bottom: 20px;
}

.custom-receipt-section h2 {
  color: #2c3e50;
  font-size: 16px;
  margin-bottom: 15px;
  padding-bottom: 5px;
  border-bottom: 2px solid #00a76f;
}

.custom-receipt-detail {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #eee;
}

.custom-receipt-detail span {
  color: #2c3e50;
  font-weight: 500;
}

.custom-receipt-detail p {
  margin: 0;
  color: #666;
}

.custom-receipt-footer {
  margin-top: 20px;
  text-align: center;
}

.custom-receipt-footer button {
  background-color: #00a76f;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.custom-receipt-footer button:hover {
  background-color: #008f5d;
}

/* --------- Fee reciept end ---------- */

/* -------------- Responsive Adjustments ----------- */
@media (max-width: 768px) {
  .message-content {
    max-width: 85%;
  }
  .coming-soon-title {
    font-size: 2.2rem;
  }

  .coming-soon-text {
    font-size: 1.1rem;
  }
  .image-setele {
    flex-direction: column-reverse;
  }
}

@media (max-width: 480px) {
  .message-container {
    padding: 15px 15px 70px 15px;
  }

  .message-content {
    max-width: 90%;
  }

  .coming-soon-title {
    font-size: 1.8rem;
  }

  .coming-soon-text {
    font-size: 1rem;
  }

  .message-input-area {
    padding: 10px 15px;
  }
  .message-list {
    padding: 0px;
  }
  .table-scroll::-webkit-scrollbar {
    width: 7px !important;
  }
  .custom-receipt {
    width: 360px !important;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .profile-image-view {
    max-width: 300px !important;
  }
  .profile-aadhar-view {
    max-width: 300px !important;
  }
}
